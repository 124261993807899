<template>
  <div class="receivelist-style">
    <div class="outbox">
      <el-card class="box-card">
        <div class="box1">{{$t('text.t243')}}</div>
        <div class="box2 between-center">
          <div class="box2-item">{{$t('text.t256')}}</div>
          <div class="box2-item">{{$t('text.t257')}}</div>
          <div class="box2-item">{{$t('text.t331')}}</div>
          <div class="box2-item">{{$t('text.t259')}}</div>
        </div>
        <div>
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('text.t39')"
            :offset="10"
            @load="getlist"
          >
            <div
              class="box3 between-center"
              v-for="item in list"
              :key="item.id"
            >
              <div class="box3-item">{{item.username.slice(0,5)}}...{{item.username.slice(item.username.length-4)}}</div>
              <div class="box3-item">{{
            item.level==-1?$t('text.t233'):
            item.level==0?$t('text.t142'):
            item.level==1?$t('text.t143'):
            item.level==2?$t('text.t144'):
            item.level==3?$t('text.t145'):
            item.level==4?$t('text.t146'):
            item.level==5?$t('text.t147'):
            item.level==6?$t('text.t148'):
            item.level==7?$t('text.t149'):
            item.level==8?$t('text.t150'):
            item.level==9?$t('text.t151'):''
            }}</div>
              <div class="box3-item">{{item.number}}</div>
              <div class="box3-item">{{item.nodepush}}</div>
            </div>
          </van-list>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        type:2
      };
      this.$http.get("/index/nodeCultivationList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.receivelist-style {
  .outbox {
    padding: 20px 0;
    .box-card {
      width: 350px;
      border-radius: 20px;
      margin: auto;
      .box1 {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 25px;
        text-align: center;
      }
      .box2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        margin-bottom: 5px;
        .box2-item {
          width: 25%;
        }
      }
      .box3 {
        font-size: 9px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        padding: 16px 0;
        border-bottom: 1px solid #e1e1e1;
        .box3-item {
          width: 25%;
        }
      }
    }
  }
}
</style>